import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import ResponsiveMenu from '../components/responsive_menu';
import Alert from '../components/alert';
import { each } from 'lodash';

document.addEventListener('DOMContentLoaded', () => {
  const menuTarget = document.getElementById('responsive-menu');
  const {dataset: {username}} = menuTarget;
  const root = createRoot(menuTarget);
  root.render(
    <StrictMode>
      <ResponsiveMenu username={username} />
    </StrictMode>
  );

  each(document.getElementsByClassName('flash-alert'), (alertTarget) => {
    const {dataset: {level}, innerHTML} = alertTarget;
    const root = createRoot(alertTarget);
    root.render(
      <StrictMode>
        <Alert level={level}>
          {innerHTML}
        </Alert>
      </StrictMode>
    );
  });
});
