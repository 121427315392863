import { useState } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';

function Alert({level, children}) {
  const [ isOpen, setOpen ] = useState(true);

  const bgColors = () => {
    switch (level) {
      case "notice":
      case "success":
        return "bg-green-50 border-green-400";
      case "error":
        return "bg-red-50 border-red-400";
      default:
        return "bg-yellow-50 border-yellow-400";
    }
  }

  const icon = () => {
    switch(level) {
      case "notice":
      case "success":
        return (
          <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
          </svg>
        );
      case "error":
        return (
          <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
          </svg>
        );
      default:
        return (
          <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
          </svg>
        );
    }
  }

  const textColor = () => {
    switch(level) {
      case "notice":
      case "success":
        return "text-green-800";
      case "error":
        return "text-red-800";
      default:
        return "text-yellow-800";
    }
  }

  const dismissColors = () => {
    switch(level) {
      case "notice":
      case "success":
        return "text-green-500 hover:bg-green-100 focus:bg-green-100";
      case "error":
        return "text-red-500 hover:bg-red-100 focus:bg-red-100";
      default:
        return "text-yellow-500 hover:bg-yellow-100 focus:bg-yellow-100";
    }
  }

  return (
    <Transition show={isOpen} appear={true}>
      <div className={`transition duration-200 ease-out data-[closed]:opacity-0 data-[closed]:scale-95 fixed inset-x-6 lg:inset-x-1/4 mt-5 z-50 rounded-md border border-l-4 ${bgColors()} p-4`}>
        <div className="flex">
          <div className="flex-shrink-0">
            {icon()}
          </div>
          <div className="ml-3">
            <div className={`text-sm leading-5 font-medium ${textColor()}`} dangerouslySetInnerHTML={{__html: children}}></div>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button type="button" onClick={() => setOpen(false)} className={`inline-flex rounded-md p-1.5 ${dismissColors()} focus:outline-none transition ease-in-out duration-150`} aria-label="Dismiss">
                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

Alert.propTypes = {
  level: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default Alert
